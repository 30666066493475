<template>
  <b-card-code
    no-body
    title="Pital Poin"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-md-12">
          <AddWalletTransaction
            :type-slug="'poin'"
            :title="'Add/Deduct Pital Poin'"
            :type-user-or-talents="'talents'"
            @getDataChild="getData"
          />
        </div>
      </div>
    </div>
    <TransactionHistories
      v-model="filter.talent_uuid"
      :type-slug="'poin'"
      :result="result"
      :is-loading="isLoading"
      :search-filter="true"
      :get-data="getData"
      :type-user-or-talents="'talents'"
      @filter="getFilter($event)"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import AddWalletTransaction from '@/components/Wallet/AddWalletTransaction.vue'
import TransactionHistories from '@/components/Wallet/TransactionHistories.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Transaction Pital Pay`
  },
  components: {
    BCardCode,
    AddWalletTransaction,
    TransactionHistories,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        talent_uuid: '',
        keyword: '',
      },
      isLoading: false,
      formPayload: Object,
      validations: '',
      editId: null,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getFilter(value) {
      this.filter = value || ''
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/wallet/poin', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.currentPage = response.data.data.current_page
          this.isLoading = false
        })
    },
  },
}
</script>
